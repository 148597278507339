declare const window :any;

export const header = function(){
    let eHeader = <HTMLElement>document.querySelector('.header');
    if(document.querySelector('.header')){
        handleToggleMb();
        scrollHeader(eHeader);
        handleSearch();
    }
}


function handleToggleMb(){
    const toggle = document.querySelector('.header-toggle-mb');
    const navHeader = document.querySelector('.header-menu>nav');

    if (toggle){
        if (toggle.classList.contains('active')){
            navHeader?.classList.add('showed-mb');
        }else{
            navHeader?.classList.remove('showed-mb');
        }

        toggle.addEventListener('click', function(){
            toggle.classList.toggle('active');
            navHeader?.classList.toggle('showed-mb');

            if (toggle.classList.contains('active')){
                document.body.classList.add('hideScroll');
            }
            else{
                document.body.classList.remove('hideScroll');
            }
        })
    }

    if (navHeader){
        const lsLi = navHeader.querySelectorAll('ul>li');
        lsLi.forEach(function(item){
            let link = item.querySelector('a');
            let icon = item.querySelector('.icon');

            link?.addEventListener('click', function(e){
                let target = <HTMLElement>e.target;
                if(target.getAttribute('href') === "javascript:void(0)"){
                    item.classList.toggle('is-actived');
                }
            })
            icon?.addEventListener('click', function(e){
                let target = <HTMLElement>e.target;
                item.classList.toggle('is-actived')
            })
        })
    }
}

function scrollHeader(eHeader:HTMLElement){
    var lastScrollTop = 0;
    var timeout: any;
    let y = 0;

    function callbackTimeout(){
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
            // downscroll code
            y = st;
            if (y > eHeader.clientHeight + 10){
                y = eHeader.clientHeight + 10;
            }
            eHeader.classList.remove('scrolling');
        } else {
            // upscroll code
            if (window.scrollY === 0){
                eHeader.classList.remove('scrolling');
            }else{
                eHeader.classList.add('scrolling');
            }
            y = 0;
        }
        lastScrollTop = st <= 0 ? 0 : st;

        eHeader.style.transform = `translateY(-${y}px)`;
    }
    
    document.addEventListener("scroll", function () {
        clearTimeout(timeout);
        timeout = setTimeout(callbackTimeout, 200);
    }, false);

}

function handleSearch(){
    const searchWrapper = document.querySelector('.header-search');

    const searchElement = document.querySelector('.header-search-icon');
    
    searchElement?.addEventListener('click', function(e){
        e.preventDefault();
        searchWrapper.classList.toggle('is-showed');
    })

    const closeSearch = searchWrapper.querySelector('.close');
    closeSearch?.addEventListener('click', function(e){
        e.preventDefault();
        searchWrapper.classList.remove('is-showed');
    })
}

