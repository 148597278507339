declare const $: any;
const viewHeight = window.innerHeight;
declare const window: any;
declare const gsap: any;
declare const ScrollTrigger: any;
gsap.registerPlugin(ScrollTrigger);
// Common function
const fetchData = () => {
	// const formData = new FormData();
	// formData.append("FirstName", "Sơn");
	// formData.append("LastName", "Vũ");
	// axios.post("/user", formData).then(function (response) {
	// 	console.log(response);
	// });

	// axios.get("./api/test.json")
	// 	.then((response) => {
	// 		return response;
	// 	})
	// 	.finally(() => {});

	// if (document.getElementById('worksPage')){
	// 	function handleLoadmoreWorks(){
	// 		const wrapper = <HTMLElement> document.querySelector('.works-section--projects');
	// 		const loadmore = wrapper?.querySelector('.loadmore');
	// 		if (loadmore){
	// 			let checkingApi = false;
	// 			function apiLoadmore(){
	// 				const top = loadmore ? loadmore.getBoundingClientRect().top : 0; 
	// 				const rectTop = top + 60;
	// 				if (rectTop <= viewHeight){
	// 					if(checkingApi === false){
	// 						// call API
	// 						const data = {
	// 							id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
	// 							page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
	// 						}

	// 						// $.ajax({
	// 						// 	url: '/api/works',
	// 						// 	type: 'GET',
	// 						// 	dataType: 'json',
	// 						// 	data: data,
	// 						// 	success: function(response:any) {
	// 						// 		handleLoadmoreWorks();
	// 						// 	},
	// 						// 	error: function(xhr:any, status:any, error:any) {
	// 						// 		console.log(error);
	// 						// 	}
	// 						// });

	// 						$.ajax({
	// 							type: 'GET',
	// 							url: `/get-load-more-product?type=${$('#changeType').attr('data-type')}`,
	// 							dataType: 'html',
	// 							success: function (res:any) {
	// 								// $('#listProducts').html(res);
	// 								let divE = document.createElement('div');
	// 								divE.innerHTML = res;
	
	// 								for (const child of divE.children) {
	// 									let cateItem = <HTMLElement>child.querySelector('.project-box__cate');
	// 									cateItem && window.effectBehavior(cateItem, "top");
										
	// 									let name = <HTMLElement>child.querySelector('.project-box__name');
	// 									name && window.effectBehavior(name, "top");
								
	// 									let desc = <HTMLElement>child.querySelector('.project-box__desc');
	// 									desc && window.effectBehavior(desc, "top");
								
	// 									let thumb = <HTMLElement>child.querySelector('.project-box-thumb');
	// 									thumb && window.effectBehavior(thumb, "top");

	// 									let more = <HTMLElement>child.querySelector('.loadmore');
	// 									more && window.effectBehavior(more, "top");
	
	// 									document.getElementById('listProducts').appendChild(child);
	// 								}

	// 								checkingApi = false;
	// 								window.addEventListener('scroll', apiLoadmore);
	// 							},
	// 							error: function(xhr:any, status:any, error:any) {
	// 								console.log(error);
	// 								checkingApi = false;
	// 							}
	// 						})
	// 						// loadmore.remove();
	
	// 						checkingApi = true;
	// 						window.removeEventListener('scroll', apiLoadmore);
	// 					}
	// 				}
	// 			}

	// 			apiLoadmore();
	// 			window.addEventListener('scroll', apiLoadmore);

	// 			loadmore.addEventListener('click', function(e){
	// 				e.preventDefault();

	// 				if(checkingApi === false){
	// 					// call API
	// 					const data = {
	// 						id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
	// 						page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
	// 					}

	// 					$.ajax({
	// 						type: 'GET',
	// 						url: `/get-load-more-product?type=${$('#changeType').attr('data-type')}`,
	// 						dataType: 'html',
	// 						success: function (res:any) {
	// 							// $('#listProducts').html(res);
	// 							let divE = document.createElement('div');
	// 							divE.innerHTML = res;

	// 							for (const child of divE.children) {
	// 								let cateItem = <HTMLElement>child.querySelector('.project-box__cate');
	// 								cateItem && window.effectBehavior(cateItem, "top");
									
	// 								let name = <HTMLElement>child.querySelector('.project-box__name');
	// 								name && window.effectBehavior(name, "top");
							
	// 								let desc = <HTMLElement>child.querySelector('.project-box__desc');
	// 								desc && window.effectBehavior(desc, "top");
							
	// 								let thumb = <HTMLElement>child.querySelector('.project-box-thumb');
	// 								thumb && window.effectBehavior(thumb, "top");

	// 								let more = <HTMLElement>child.querySelector('.loadmore');
	// 								more && window.effectBehavior(more, "top");

	// 								document.getElementById('listProducts').appendChild(child);
	// 							}

	// 							checkingApi = false;
	// 							window.addEventListener('scroll', apiLoadmore);
	// 						},
	// 						error: function(xhr:any, status:any, error:any) {
	// 							console.log(error);
	// 							checkingApi = false;

	// 							let divE = document.createElement('div');
	// 							divE.innerHTML = `
	// 							<li> 
	// 								<div class="project-box gs_reveal"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">BINH TIEN GOLF DRIVING RANGE</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Through many restorations, Con market has become a place to trade and exchange goods associated with local people and an attractive shopping and dining place for tourists.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box gs_reveal"> 
	// 									<div class="project-box-content"> 
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Eagle House.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="Eagle House."></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box gs_reveal"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Hai Chau Administraive Center.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="Hai Chau Administraive Center."></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Hi-Tech Center Ditp.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="Hi-Tech Center Ditp."></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Apartment Ditp.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="Apartment Ditp."></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">CA TIEN VILLA.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Halfway.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Eagle House.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">DAK LAK CUTURE HOUSE.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Con Market.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Quoc Anh Villa</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Ohana’s Assembly Hall.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Hi-Tech Center Ditp.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">Golden Hill.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">DAK LAK CUTURE HOUSE.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">DAK LAK CUTURE HOUSE.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							<li> 
	// 								<div class="project-box"> 
	// 									<div class="project-box-content">
	// 										<p class="project-box__cate">ARCHITECTURE</p>
	// 										<div class="project-box__name uppercase"> 
	// 											<h2><a href="./works-detail.html">DAK LAK CUTURE HOUSE.</a></h2>
	// 										</div>
	// 										<div class="project-box__desc content16"> 
	// 											<p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
	// 										</div>
	// 									</div>
	// 									<div class="project-box-thumb"><a href="./works-detail.html"><img src="./assets/images/projects/binh-tien-golf-driving-range.jpg" alt="BINH TIEN GOLF DRIVING RANGE"></a></div>
	// 								</div>
	// 							</li>
	// 							`;


	// 						}
	// 					})
	// 					// loadmore.remove();

	// 					checkingApi = true;
	// 					window.removeEventListener('scroll', apiLoadmore);
	// 				}
	// 			})
	// 		}
	// 	}

	// 	handleLoadmoreWorks();
	// }
	// function LoadMoreDataProduct() {
	// 	$.ajax({
	// 		type: 'GET',
	// 		url: `/get-load-more-product?type=${$('#changeType').attr('data-type')}`,
	// 		dataType: 'html',
	// 		success: function (res:any) {
	// 			$('#listProducts').html(res);
	// 		}
	// 	})
	// }
	
	if (document.getElementById('newsPage')){
		const handleLoadmoreNews = function(){
			const wrapper = <HTMLElement> document.querySelector('.news-section-wrapper');
			const loadmore = wrapper?.querySelector('.loadmore');
	
			if (loadmore){
				let checkingApi = false;
				function apiLoadmore(){
					const top = loadmore ? loadmore.getBoundingClientRect().top : 0; 
					const rectTop = top + 60;
	
					if (rectTop <= viewHeight){
						if(checkingApi === false){
							// call API
							const data = {
								id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
								page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
							}
		
							$.ajax({
								url: '/api/newsloadmore',
								type: 'GET',
								dataType: 'html',
								data: data,
								success: function(response:any) {
									loadmore.remove();
									const ul = wrapper.querySelector('ul');

									let htmlE = document.createElement('div');
									htmlE.innerHTML = response;

									for (const child of htmlE.children) {
										let boxesNews = child.querySelectorAll('.box-news');
										boxesNews.forEach(function(boxItem){
											let thumb = boxItem.querySelector('.thumbnail');
											thumb && window.effectBehavior(thumb, 'left');
								
											let time = boxItem.querySelector('.content time');
											time && window.effectBehavior(time);
								
											let title = boxItem.querySelector('.content h2');
											title && window.effectBehavior(title);
								
											let desc = boxItem.querySelector('.content .content-desc');
											desc && window.effectBehavior(desc);
										})
								
										let more = child.querySelector('.loadmore');
										more && window.effectBehavior(more);

										ul.appendChild(child);
									}
									
									handleLoadmoreNews();
								},
								error: function(xhr:any, status:any, error:any) {
									console.log(error);
									checkingApi = false;
								}
							});
						}

						checkingApi = true;
						window.removeEventListener('scroll', apiLoadmore);
					}
				}
				apiLoadmore();
				window.addEventListener('scroll', apiLoadmore);
	
				loadmore.addEventListener('click', function(e){
					e.preventDefault();
					if(checkingApi === false){
						// call API
						const data = {
							id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
							page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
						}
	
						$.ajax({
							url: '/api/newsloadmore',
							type: 'GET',
							dataType: 'html',
							data: data,
							success: function(response:any) {
								// loadmore.remove();

								// const ul = wrapper.querySelector('ul');
								// ul.innerHTML += response;

								// handleLoadmoreNews();

								loadmore.remove();
								const ul = wrapper.querySelector('ul');

								let htmlE = document.createElement('div');
								htmlE.innerHTML = response;

								for (const child of htmlE.children) {
									let boxesNews = child.querySelectorAll('.box-news');
									boxesNews.forEach(function(boxItem){
										let thumb = boxItem.querySelector('.thumbnail');
										thumb && window.effectBehavior(thumb, 'left');
							
										let time = boxItem.querySelector('.content time');
										time && window.effectBehavior(time);
							
										let title = boxItem.querySelector('.content h2');
										title && window.effectBehavior(title);
							
										let desc = boxItem.querySelector('.content .content-desc');
										desc && window.effectBehavior(desc);
									})
							
									let more = child.querySelector('.news-section-wrapper .loadmore');
									more && window.effectBehavior(more);

									ul.appendChild(child);
								}
								
								handleLoadmoreNews();
							},
							error: function(xhr:any, status:any, error:any) {
								console.log(error);
								checkingApi = false;
							}
						});
						// loadmore.remove();
	
						checkingApi = true;
						window.removeEventListener('scroll', apiLoadmore);
					}
				})
			}

			const wrapperMedia = <HTMLElement> document.querySelector('.news-section-media');
			const loadmoreMedia = wrapperMedia?.querySelector('.loadmore');
	
			if (loadmoreMedia){
				let checkingApi = false;
				function apiMediaLoadmore(){
					const top = loadmoreMedia ? loadmoreMedia.getBoundingClientRect().top : 0; 
					const rectTop = top + 60;
	
					if (rectTop <= viewHeight){
						if(checkingApi === false){
							// call API
							const data = {
								id: loadmoreMedia.getAttribute('data-id') ? loadmoreMedia.getAttribute('data-id') : "",
								page: loadmoreMedia.getAttribute('data-page') ? loadmoreMedia.getAttribute('data-page') : ""
							}
		
							$.ajax({
								url: '/api/medialoadmore',
								type: 'GET',
								dataType: 'html',
								data: data,
								success: function(response:any) {
									loadmoreMedia.remove();
									const ul = wrapperMedia.querySelector('ul');

									let htmlE = document.createElement('div');
									htmlE.innerHTML = response;

									for (const child of htmlE.children) {
										let boxesMedia = child.querySelectorAll('.media-item');
										boxesMedia.forEach(function(boxItem, idx){
											if (idx % 2 === 0){
												window.effectBehavior(boxItem, 'left');              
											}else{
												window.effectBehavior(boxItem, 'right'); 
											}
										})
								
										let moreMedia = child.querySelector('.loadmore');
										moreMedia && window.effectBehavior(moreMedia);

										ul.appendChild(child);
									}
									
									handleLoadmoreNews();
								},
								error: function(xhr:any, status:any, error:any) {
									console.log(error);
									checkingApi = false;
								}
							});
						}

						checkingApi = true;
						window.removeEventListener('scroll', apiMediaLoadmore);
					}
				}
				apiMediaLoadmore();
				window.addEventListener('scroll', apiMediaLoadmore);
	
				loadmoreMedia.addEventListener('click', function(e){
					e.preventDefault();
					if(checkingApi === false){
						// call API
						const data = {
							id: loadmoreMedia.getAttribute('data-id') ? loadmoreMedia.getAttribute('data-id') : "",
							page: loadmoreMedia.getAttribute('data-page') ? loadmoreMedia.getAttribute('data-page') : ""
						}
	
						$.ajax({
							url: '/api/medialoadmore',
							type: 'GET',
							dataType: 'html',
							data: data,
							success: function(response:any) {
								loadmoreMedia.remove();
									const ul = wrapperMedia.querySelector('ul');

									let htmlE = document.createElement('div');
									htmlE.innerHTML = response;

									for (const child of htmlE.children) {
										let boxesMedia = child.querySelectorAll('.media-item');
										boxesMedia.forEach(function(boxItem, idx){
											if (idx % 2 === 0){
												window.effectBehavior(boxItem, 'left');              
											}else{
												window.effectBehavior(boxItem, 'right'); 
											}
										})
								
										let moreMedia = child.querySelector('.loadmore');
										moreMedia && window.effectBehavior(moreMedia);

										ul.appendChild(child);
									}
									
									handleLoadmoreNews();
							},
							error: function(xhr:any, status:any, error:any) {
								console.log(error);
								checkingApi = false;
							}
						});
						// loadmore.remove();
	
						checkingApi = true;
						window.removeEventListener('scroll', apiMediaLoadmore);
					}
				})
			}
		}

		handleLoadmoreNews();
	}

	if (document.getElementById('searchesPage')){
		const handleLoadmoreSearch = function(){
			const wrapper = <HTMLElement> document.querySelector('.news-section-wrapper');
			const loadmore = wrapper?.querySelector('.loadmore');
	
			if (loadmore){
				let checkingApi = false;
				function apiLoadmore(){
					const top = loadmore ? loadmore.getBoundingClientRect().top : 0; 
					const rectTop = top + 60;
	
					if (rectTop <= viewHeight){
						if(checkingApi === false){
							// call API
							const data = {
								id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
								page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
							}
		
							$.ajax({
								url: '/api/searchloadmore',
								type: 'GET',
								dataType: 'html',
								data: data,
								success: function(response:any) {
									// loadmore.remove();

									// const ul = wrapper.querySelector('ul');
									// ul.innerHTML += response;
									
									// handleLoadmoreSearch();

									loadmore.remove();
									const ul = wrapper.querySelector('ul');

									let htmlE = document.createElement('div');
									htmlE.innerHTML = response;

									for (const child of htmlE.children) {
										let boxesNews = child.querySelectorAll('.box-news');
										boxesNews.forEach(function(boxItem){
											let thumb = boxItem.querySelector('.thumbnail');
											thumb && window.effectBehavior(thumb, 'left');
								
											let time = boxItem.querySelector('.content time');
											time && window.effectBehavior(time);
								
											let title = boxItem.querySelector('.content h2');
											title && window.effectBehavior(title);
								
											let desc = boxItem.querySelector('.content .content-desc');
											desc && window.effectBehavior(desc);
										})
								
										let more = child.querySelector('.news-section-wrapper .loadmore');
										more && window.effectBehavior(more);

										ul.appendChild(child);
									}
									
									handleLoadmoreSearch();
								},
								error: function(xhr:any, status:any, error:any) {
									console.log(error);
									checkingApi = false;
								}
							});
						}

						checkingApi = true;
						window.removeEventListener('scroll', apiLoadmore);
					}
				}
				apiLoadmore();
				window.addEventListener('scroll', apiLoadmore);
	
				loadmore.addEventListener('click', function(e){
					e.preventDefault();
					if(checkingApi === false){
						// call API
						const data = {
							id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
							page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
						}
	
						$.ajax({
							url: '/api/searchloadmore',
							type: 'GET',
							dataType: 'html',
							data: data,
							success: function(response:any) {
								// loadmore.remove();

								// const ul = wrapper.querySelector('ul');
								// ul.innerHTML += response;

								// handleLoadmoreSearch();

								loadmore.remove();
								const ul = wrapper.querySelector('ul');

								let htmlE = document.createElement('div');
								htmlE.innerHTML = response;

								for (const child of htmlE.children) {
									let boxesNews = child.querySelectorAll('.box-news');
									boxesNews.forEach(function(boxItem){
										let thumb = boxItem.querySelector('.thumbnail');
										thumb && window.effectBehavior(thumb, 'left');
							
										let time = boxItem.querySelector('.content time');
										time && window.effectBehavior(time);
							
										let title = boxItem.querySelector('.content h2');
										title && window.effectBehavior(title);
							
										let desc = boxItem.querySelector('.content .content-desc');
										desc && window.effectBehavior(desc);
									})
							
									let more = child.querySelector('.news-section-wrapper .loadmore');
									more && window.effectBehavior(more);

									ul.appendChild(child);
								}
								
								handleLoadmoreSearch();
							},
							error: function(xhr:any, status:any, error:any) {
								console.log(error);
								checkingApi = false;
							}
						});
						// loadmore.remove();
	
						checkingApi = true;
						window.removeEventListener('scroll', apiLoadmore);
					}
				})
			}
		}

		handleLoadmoreSearch();
	}

	if (document.getElementById('aboutPage')){
		
		const handleLoadmoreProfile = function(){
			const wrapper = <HTMLElement> document.querySelector('.ab-section-wrapper .article-ls');
			const loadmore = wrapper?.querySelector('.loadmore');
	
			if (loadmore){
				let checkingApi = false;
				// function apiLoadmore(){
				// 	const top = loadmore ? loadmore.getBoundingClientRect().top : 0; 
				// 	const rectTop = top + 60;
	
				// 	if (rectTop <= viewHeight){
				// 		if(checkingApi === false){
				// 			// call API
				// 			const data = {
				// 				id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
				// 				page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
				// 			}
		
				// 			$.ajax({
				// 				url: '/api/profileloadmore',
				// 				type: 'GET',
				// 				dataType: 'html',
				// 				data: data,
				// 				success: function(response:any) {
				// 					loadmore.remove();

				// 					const ul = wrapper.querySelector('ul');
				// 					ul.innerHTML += response;
									
				// 					handleLoadmoreProfile();
				// 				},
				// 				error: function(xhr:any, status:any, error:any) {
				// 					console.log(error);
				// 					checkingApi = false;
				// 				}
				// 			});
				// 		}

				// 		checkingApi = true;
				// 		window.removeEventListener('scroll', apiLoadmore);
				// 	}
				// }
				// apiLoadmore();
				// window.addEventListener('scroll', apiLoadmore);
	
				loadmore.addEventListener('click', function(e){
					e.preventDefault();
					if(checkingApi === false){
						// call API
						const data = {
							id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
							page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
						}
	
						$.ajax({
							url: '/api/profileloadmore',
							type: 'GET',
							dataType: 'html',
							data: data,
							success: function(response:any) {
								loadmore.remove();

								// const ul = wrapper.querySelector('ul');
								wrapper.innerHTML += response;

								handleLoadmoreProfile();
							},
							error: function(xhr:any, status:any, error:any) {
								console.log(error);
								checkingApi = false;
							}
						});
						// loadmore.remove();
	
						checkingApi = true;
						// window.removeEventListener('scroll', apiLoadmore);
					}
				})
			}
		}

		handleLoadmoreProfile();
	}
};

export default {
	fetchData: fetchData,
}