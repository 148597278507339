declare const window: any;
const viewHeight = window.innerHeight;

export const exploreScroll = function(){
    let timeoutScroll:any;
    let scrollToExplore = <HTMLElement> document.querySelector('.scrollToExplore');
    if (scrollToExplore){
        function handleScroll (){
            clearTimeout(timeoutScroll);
            const hBody = document.body.clientHeight;
            if (hBody === viewHeight){
                let timeoutDislay:any;
                timeoutScroll = setTimeout(function(){
                    scrollToExplore.style.display = "none";
                    clearTimeout(timeoutDislay);
                    timeoutDislay = setTimeout(function(){
                        scrollToExplore.style.display = "inline-flex";
                        scrollToExplore.style.animation = "viewExplore 1s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275)";
                    }, 3000)
                }, 300)
            }
            else {

                if ( window.scrollY + viewHeight < hBody - 120){

                    let timeoutDislay:any;
                    timeoutScroll = setTimeout(function(){
                        scrollToExplore.style.display = "none";
    
                        clearTimeout(timeoutDislay);
                        timeoutDislay = setTimeout(function(){
                            scrollToExplore.style.display = "inline-flex";
                            scrollToExplore.style.animation = "viewExplore 1s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275)";
                        }, 3000)
                    }, 300)
                }
                else{
                    scrollToExplore.style.display = "none";
                }
            }

        }
        handleScroll();
        document.addEventListener('scroll', handleScroll)
    }
}