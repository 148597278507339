declare const window: any;
declare const Swiper: any;
declare const $: any;

const viewHeight = window.innerHeight;

const defaultArrange = "galary";

var arrange = defaultArrange;

export const works = function () {
    let elem = document.getElementById('worksPage');
    if (elem){
        $.ajax({
            type: 'GET',
            url: `/get-product?type=${$('#changeType').attr('data-type')}`,
            dataType: 'html',
            success: function (res:any) {
                $('#listProducts').html(res);
            }
        })

        if (localStorage["arrange"]){
            arrange = localStorage["arrange"];
        }else{
            localStorage["arrange"] = defaultArrange;
        }

        handleView();
        handleCateMb();
        handleSwThumb();
    }
};

function handleCateMb(){
    const projecttype = document.querySelector('.projecttype');
    const opt = projecttype?.querySelector('.works-section .projecttype-opt');
    if (opt){
        opt.addEventListener('click', function(e){
            e.preventDefault();
            projecttype.classList.toggle('is-showed');
        })
    }
}

function handleView(){
    const optsGalary = document.querySelectorAll('.works-section__galary');
    const viewGalary = <HTMLElement> document.querySelector('.works-section--projects');

    optsGalary.forEach(function(optGalary:HTMLElement){
        if (optGalary && viewGalary){
            // let typeOpt = optGalary.dataset.type;
            let arrType = optGalary.querySelectorAll('[data-select]');            
            if (arrType.length < 1){
                // array undifined
                return;
            }
    
            // if (!arrange && arrType.length > 0){
            //     const temp = <HTMLElement> arrType[0];
            //     typeOpt = temp.dataset.select;
            // }
            
            viewGalary.dataset.type = arrange;
            optsGalary.forEach(function(setOptGalary:HTMLElement){
                setOptGalary.dataset.type = arrange;
            })
    
            arrType.forEach(function(itemType: HTMLElement){
                itemType.addEventListener('click', function(e){
                    e.preventDefault();
                    let change = itemType.dataset.change;
                    
                    viewGalary.dataset.type = change;
                    localStorage["arrange"] = change;
                    arrange = change;

                    optsGalary.forEach(function(tempOpt:HTMLElement){
                        tempOpt.dataset.type = change;
                    })

                    let toId = document.getElementById('scrollProjects');
                    if (toId){
                        const cateOffsetTop = window.getOffsetTopElement(toId);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth"		
                        });
                    }

                    // if (optGalary.classList.contains('works-section__galary--fixed')){
                    //     const cate = document.querySelector('.works-section--categories');
                    //     const cateOffsetTop = window.getOffsetTopElement(cate);

                    //     window.scrollTo({
                    //         top: cateOffsetTop,
                    //         left: 0,
                    //         behavior: "smooth"				
                    //     });
                    // }
                })
            })

            // window.addEventListener('scroll', function(e:any){
            //     console.log('window');
            // })
            // document.addEventListener('scroll', function(e:any){
            //     console.log('document');
            // })
        }
    })


}

function initSwiperSlider(target:HTMLElement){
    const sw = new Swiper(target, {
        zoom: true,

        // autoplay: {
        //     delay: 2400,
        //     pauseOnMouseEnter: true,
        //     disableOnInteraction: false,
        // },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },

        effect: "fade",
    })
    return sw;
}

function initSwiperThumb(target:HTMLElement){
    const sw = new Swiper(target, {
        slidesPerView: "auto",
        spaceBetween: 10,
    })
    return sw;
}

function handleSwThumb(){
    const thumb = <HTMLElement>document.querySelector('.works-section .swiper-thumbSlider');
    const slider = <HTMLElement>document.querySelector('.works-section .swiper-selectSlider');
    if (thumb && slider){
        const swSlider = initSwiperSlider(slider);
        const swThumb = initSwiperThumb(thumb);
    
        const lsThumb = thumb.querySelectorAll('.swiper-slide');
        window.delHandle(lsThumb, 'is-actived');
        lsThumb[0].classList.add('is-actived');

        swSlider.on('slideChange', function () {
            let activeIdx = swSlider.activeIndex;
            swThumb.slideTo(activeIdx);
            
            window.delHandle(lsThumb, 'is-actived');
            lsThumb[activeIdx].classList.add('is-actived');
        });
    
        swThumb.on('slideChange', function(){
            let activeIdx = swThumb.activeIndex;
            swSlider.slideTo(activeIdx);
    
            window.delHandle(lsThumb, 'is-actived');
            lsThumb[activeIdx].classList.add('is-actived');
        })
    
        lsThumb.forEach(function(itemThumb, idx){
            itemThumb.addEventListener('click', function(){
                swSlider.slideTo(idx);
            })
        })
    }
}