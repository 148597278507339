declare const window: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
gsap.registerPlugin(ScrollTrigger);

const _window = window;

export const _base = function () {
	window.delHandle = function (list: any, str: any) {
		if (typeof str == "string") {
			list.forEach(function (item: HTMLElement) {
				item.classList.remove(str);
			});
		}
	};

	window.getQueryString = function (params: any) {
		var str = Object.keys(params)
			.map(function (key) {
				return key + "=" + params[key];
			})
			.join("&");
		return str;
	};

	window.getPathnames = function(){
		const pathArray = window.location.pathname.split('/');
		return pathArray;
	}

	window.getParams = function(){
		const urlSearchParams = new URLSearchParams(window.location.search);
		console.log('search', urlSearchParams);
		const params = Object.fromEntries(urlSearchParams.entries());
		return params;
	}

	window.setPushState = function(obj:any){
		let params = window.getParams();
		for (let key in obj) {
			params[key] = obj[key];
		}

		let queryStr = window.getQueryString(params);
		window.history.pushState("", "", "?" + queryStr);
		return true;
    }

	window.delParamsAll = function(){
		try{
			history.pushState(null, null, window.location.pathname);
			return true;
		}
		catch(err){
			throw err;
		}
	}

	// mouse in seemore box
	window.mouseBoxHandle = function (idSection: string, strHandle: string) {
		let box = document.getElementById(idSection);
		if (!box) {
			return;
		}
		let children = box.children;
		let mouseHandle = <HTMLElement>box?.querySelector(strHandle);
		let checkClick = false;
		let interval: any;

		// if (window.innerWidth < 1024){
		//   checkClick = true;
		// }

		for (var i = 0; i < children.length; ++i) {
			let ele = <HTMLElement>children[i];
			if (ele.classList.contains("item")) {
				ele.setAttribute("dt-item", i.toString());
			}
		}

		box.style.position = "relative";
		box.addEventListener("mousemove", function (evt) {
			let targetE = <HTMLElement>evt.target;
			if (targetE.classList.contains("item")) {
				let iItem = parseInt(targetE.getAttribute("dt-item"));
				mouseHandle.style.display = "inline-flex";
				let x = evt.offsetX / (window.innerWidth / 100);
				let y =
					(evt.offsetY + targetE.offsetHeight * (iItem - 1)) /
					(window.innerHeight / 100);
				mouseHandle.style.left = x + "vw";
				mouseHandle.style.top = y + "vh";
			} else {
				mouseHandle.style.display = "";
			}
			if (!checkClick) {
				setInterval(function () {
					checkClick = true;
				}, 500);
			}
		});

		box.addEventListener("mouseleave", function (evt) {
			mouseHandle.style.display = "";
			clearInterval(interval);
			checkClick = false;
		});

		for (var i = 0; i < children.length; ++i) {
			let ele = <HTMLElement>children[i];
			if (ele.classList.contains("item")) {
				ele.addEventListener("click", function (e) {
					if (!checkClick) {
						e.preventDefault();
					}
				});
			}
		}
	};

	window.copyMaker = function(copyElement:HTMLElement, callback:Function){
		let textCopy = copyElement.getAttribute('href');
		if (!textCopy || textCopy === "#"){
			textCopy = window.location.href;
		}

		const textElementSuccess = copyElement.getAttribute("dt-ss") + "";
		const textElementError = copyElement.getAttribute("dt-er") + "";

		try{	
			copyElement.removeAttribute('dt-ss');
			copyElement.removeAttribute('dt-er');

			copyElement.addEventListener("click", function(e){
				e.preventDefault();
				const input = document.createElement("input");
				input.value = textCopy;
				input.setAttribute('type', 'text');
				input.style.height = "0";
				input.style.opacity = "0";
				let body = document.querySelector('body');
				body.appendChild(input);

				input.select();

				document.execCommand("copy");

				input.remove();

				// window.getSelection().selectAllChildren(input);
				// input.execCommand("copy");

				copyElement.setAttribute("tooltip", textElementSuccess);

				callback('success', textElementSuccess);
			});

			// copyElement.addEventListener("mouseover", function(e){
			// 	copyElement.setAttribute("tooltip", textElement);
			// });
		}
		catch{
			callback('error', textElementError);
		}
	}

	window.addToastMsg = function(status:string, message:string){
		let toastMsg = <HTMLElement>document.querySelector('.toast-msg');
		if (!toastMsg){
			let eToast = document.createElement('ul');
			eToast.classList.add('toast-msg');

			toastMsg = eToast;

			document.body.appendChild(toastMsg);
		}

		let item = document.createElement('li');
		
		item.classList.add(status);

		item.innerHTML = `
			<p>${message}</p>
		`;
		let timeMsg = 3000;
		let timeDurationMsg = 600;

		const iChild = 5;

		item.style.transitionDuration = (timeDurationMsg / 1000) + "s";

		item.addEventListener('click', function(){
			let promise = new Promise(function(res, rej){
				item.classList.remove('active');
				res(item);
			})
			promise.then(function(value){
				setTimeout(function(){
					item.remove();
				}, timeDurationMsg)
			})
		})

		// time message
		let timeout = new Promise(function(resole, reject){
			try{
				if (!item){
					throw 'Not item message!';
				}
				setTimeout(function(){
					item.classList.add('active');
					resole(item);
				}, 0)
			}
			catch(err){
				reject(err)
			}
		})

		timeout.then(function(item:HTMLElement){
			return new Promise(function(resole, reject){
				setTimeout(function(){
					item.classList.remove('active');
					resole(item);
				}, timeMsg)
			})
		}).then(function(value:HTMLElement){
			setTimeout(function(){
				value.remove();

				// remove Toast list
				if (toastMsg.children.length === 0){
					toastMsg.remove();
				}
			}, timeDurationMsg)
		})
		.catch(function(error){
			console.error(`${error}`)
		})

		if (toastMsg.children.length + 1 > iChild ){
			toastMsg.children[0].remove();
		}

		toastMsg.appendChild(item);
	}

	window.targetHandle = function(target:any = document,checkCondition:Function, callBack:Function){
		target.addEventListener('click', function(e:any){
			let target = e.target;

			if (checkCondition(target) === true){
				return;
			}

			target = target.parentElement;
			do{
				if (checkCondition(target) === true){
				return;
				}
				target = target.parentElement;
			}while(target.parentElement);

			callBack();
		})
	}

	function closeHandle(target:any, callBack:Function){
		target.classList.remove('opened');

		callBack(target);
	}

	window.popupHandle = function(target:any, callBackClose:Function = function(){return""}){
		try{
			if (typeof target === 'string'){
				target = document.querySelector(`${target}`);
			}

			let popup = document.querySelector(`${target.getAttribute('data-popup')}`);
			if (!popup){
				throw `Not found popup: ${target.getAttribute('data-popup')}`;
			}

			if (popup.classList.contains('initialized')){
				return;
			}

			let close = popup.querySelector('.close');
			if (close){
				close.addEventListener('click', function(e:any){
					e.preventDefault();
					closeHandle(popup, callBackClose);
				});
			}
			
			let cancel = popup.querySelector('.cancel');
			if (cancel){
				cancel.querySelector('a').addEventListener('click', function(e:any){
					e.preventDefault();
					closeHandle(popup, callBackClose);
				})
			}

			_window.targetHandle(popup, function(e:HTMLElement){
				if (e.classList.contains('wrapper')){
					return true;
				}
				return false;
			}, function(){
				closeHandle(popup, callBackClose);
			})
			
			target.addEventListener('click', function(e:any){
				e.preventDefault();
				popup.classList.add('opened');
			})

			popup.classList.add("initialized");
		}
		catch(err){
			console.error(err);
		}
	}

	window.initPopupHandle = function(target:any, callBackClose:Function = function(){return""}){
		try{
			let popup = target;
			if (typeof target === "string"){
				popup = document.querySelector(target);
			}
			if (!popup){
				throw `Not found popup: ${target.getAttribute('data-popup')}`;
			}

			if (popup.classList.contains('initialized')){
				popup.classList.add('opened');
				return;
			}

			let close = popup.querySelector('.close');
			if (close){
				close.addEventListener('click', function(e:any){
					e.preventDefault();
					closeHandle(popup, callBackClose);
				});
			}
			
			let cancel = popup.querySelector('.cancel');
			if (cancel){
				cancel.querySelector('a').addEventListener('click', function(e:any){
					e.preventDefault();
					closeHandle(popup, callBackClose);
				})
			}

			_window.targetHandle(popup, function(e:HTMLElement){
				if (e.classList.contains('wrapper')){
					return true;
				}
				return false;
			}, function(){
				closeHandle(popup, callBackClose);
			})
			
			// target.addEventListener('click', function(e:any){
			// 	e.preventDefault();
			// })
			
			popup.classList.add('opened');
			popup.classList.add("initialized");
		}
		catch(err){
			console.error(err);
		}
	}

	window.backToTop = (strQuery: string, target:HTMLElement = null) => {
		const backtotop = <HTMLElement> document.querySelector(strQuery);
		if (backtotop){
			let addEvt = false;
			let prevScrollpos = window.pageYOffset;

			const handleScroll = () => {
				if (target){
					let top = target.getBoundingClientRect().top;
					if (top <= window.innerHeight + 200){
						backtotop.classList.add("showed");
						return true;
					}
					backtotop.classList.remove('showed');
				}
				else{
					if (window.scrollY > 500){
						backtotop.classList.add('showed');
						return true;
					}
					backtotop.classList.remove('showed');
				}
				
				return false;
			}

			handleScroll();
			let timeout:any;
			
			function fHandleScroll(){
				let currentScrollPos = window.pageYOffset;
				if (prevScrollpos > currentScrollPos) {
					backtotop.classList.remove('showed');
				} else {
					clearTimeout(timeout);
					timeout = setTimeout(handleScroll, 200);
				}
				prevScrollpos = currentScrollPos;
			}

			addEvt = true;
			window.addEventListener('scroll', fHandleScroll);

			if(window.innerWidth <= 768){
				backtotop.classList.remove('showed');
				addEvt = false;
				window.removeEventListener('scroll', fHandleScroll);
			}

			window.addEventListener('resize', function(){
				if(window.innerWidth <= 768){
					backtotop.classList.remove('showed');
					if(addEvt){
						addEvt = false;
						window.removeEventListener('scroll', fHandleScroll);
					}
				}else{
					if(!addEvt){
						addEvt = true;
						window.addEventListener('scroll', fHandleScroll);
					}
				}
			});

			const handleClick = (e:any) =>{
				e.preventDefault();	
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth"				
				});
			}
			backtotop.onclick = handleClick;
		}
	}
	window.getOffsetTopElement = function(element:HTMLElement){
		let offTop = 0;
		let i = 0;
		let tempElement = element;
		do{
			
			if (tempElement && i < 100){
				offTop += tempElement.offsetTop;
				tempElement = <HTMLElement>tempElement.offsetParent;
				++i;
			}else{
				break;
			}
		}while(tempElement);

		return offTop;
	}

	window.effectBehavior = function(elem:any, behavior:any = "top"){
		gsap.set(elem, {autoAlpha: 0});
        
		ScrollTrigger.create({
			trigger: elem,
			onEnter: function() { 
				var x = 0,
				y = 100;

				if (behavior == 'left'){
					x = -100;
					y = 0;
				}
				else if (behavior == 'right'){
					x = 100;
					y = 0;
				}
			
				elem.style.transform = "translate(" + x + "px, " + y + "px)";

				elem.style.opacity = "0";
				gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
					duration: 1.25, 
					x: 0,
					y: 0, 
					autoAlpha: 1, 
					ease: "expo", 
					overwrite: "auto"
				});
			 }, 
			// onEnterBack: function() { animationGsap(elem, behavior) },
			// onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
		});
	}
};
