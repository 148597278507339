declare const window: any;
declare const Swiper: any;
declare const $: any;


export const banner = function (){

}


// function sw1(){
//     var sw = new Swiper(".sub-banner .swiper", {
//         loop: true,
//         effect: 'fade',

//         speed: 1200,

//         autoplay: {
//             delay: 4000
//         },

//         pagination: {
//             el: ".sub-banner .swiper-pagination",
//             clickable: true,
//             dynamicBullets: true,
//         },
//     })
// }