declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

const viewHeight = window.innerHeight;
const viewWidth = window.innerWidth;

let ghandleClose:any;
let aboutPageSection = <HTMLElement> document.querySelector('.ab-section');
const titlePage = aboutPageSection?.getAttribute('data-titlePage');

export const about = function () {
    let elem = document.getElementById('aboutPage');
    if (elem){
        // handleGroupBox();
        handleUrl();
    }
};

function handlePopuped(element:HTMLElement, list:any, cancel:boolean = false){
    let detail = <HTMLElement> element.querySelector('.item-detail');
    if (detail){
        // element.classList.add('showed');
        element.classList.add('is-animated');
        document.body.classList.add('hideScroll');
        
        const backToAll = detail.querySelector('.backtoall');
        const close = detail.querySelector('.close');

        function handleClose(e:any = null){
            if (e){
                e.preventDefault();
            }

            const closeHref = backToAll.getAttribute('href') || "javascript:void(0)";

            backToAll.removeEventListener('click', handleClose);
            close.removeEventListener('click', handleClose);
            document.removeEventListener('keyup', escHandle);

            element.classList.remove('showed');
            element.classList.remove('is-animated');

            document.body.classList.remove('hideScroll');

            if (closeHref && closeHref !== "javascript:void(0)"){
                // window.location.href = close.getAttribute('href');
                window.history.pushState(null, '', closeHref);
                
                document.title = titlePage || document.title;
            }

            list.forEach(function(temp:HTMLElement){
                temp.style.userSelect = "";
                temp.style.pointerEvents = "";
            })
        }

        ghandleClose = handleClose;
        function escHandle(event:any){
            if (event.key === "Escape") {
              handleClose();
            }
        }

        if (cancel=== false){            
            document.addEventListener('keyup', escHandle);
    
            backToAll.addEventListener('click',handleClose);
            close.addEventListener('click', handleClose);
        }
        else{
            handleClose();
        }
    }
}

function handleUrl(){
    // const params = window.getParams();
    // const val = params.name;
    // const team = params.team;
    // console.log(params);
    const originName = window.location.origin;
    const items = document.querySelectorAll('.ab-section-ls .item');

    var checkoriginName = false;
    items.forEach(function(item:HTMLElement, id){
        if (item.classList.contains('showed')){
            items.forEach(function(temp:HTMLElement){
                temp.style.userSelect = "none";
                temp.style.pointerEvents = "none";
            })
            
            item.scrollIntoView();
            handlePopuped(item, items);
        }

        const link = item.querySelector('.item--link');
        link.addEventListener('click', function(e){
            e.preventDefault();
            item.classList.add('showed');

            const query = item.dataset.query;
            window.history.pushState({id}, `Selected: ${id}`, query);
            document.title = item.dataset.title || item.querySelector('.item-content .item__name').textContent || document.title;

            setTimeout(function(){
                handlePopuped(item, items);
            }, 0)
        })
    })

    window.addEventListener('popstate', function(e:any){
        if (e.state){
            const itempop = <HTMLElement> items[e.state.id];
            itempop.classList.add('showed');

            setTimeout(function(){
                handlePopuped(itempop, items);
            }, 0)
        }
        else{
            items.forEach(function(itemDel:HTMLElement){
                if (itemDel.classList.contains('showed')){
                    handlePopuped(itemDel, items, true);
                }
            })
        }
    })



    // if (!checkPathname){
    //     items.forEach(function(item:HTMLElement){
    //         if (item.classList.contains('showed')){
    //             handlePopuped(item);
    //         }
    //         else{
    //             let detail = <HTMLElement> item.querySelector('.item-detail');
    
    //             const backToAll = detail.querySelector('.backtoall');
    //             const close = detail.querySelector('.close');
    
    //             backToAll.removeEventListener('click', ghandleClose);
    //             close.removeEventListener('click', ghandleClose);
    
    //             item.classList.remove('showed');
    //             document.body.classList.remove('hideScroll');
    //         }
    //     })
    // }
}

// function handleGroupBox(){
//     const items = document.querySelectorAll('.ab-section-ls .item');
//     items.forEach(function(item:HTMLElement){
//         const link = item.querySelector('.item--link');
//         let timeOutFirst:any;

//         link?.addEventListener('click', function(e){
//             clearTimeout(timeOutFirst);
            
//             document.body.style.pointerEvents = "none";

//             // e.preventDefault();
//             const rect = item.getBoundingClientRect();

//             let detail = <HTMLElement> item.querySelector('.item-detail');
//             if (detail){
//                 detail.style.position = "fixed";
//                 detail.style.top = rect.top + "px";
//                 detail.style.left = rect.left + "px";
//                 detail.style.width = rect.width + "px";
//                 detail.style.height = rect.height + "px";
//                 detail.classList.add('visible');
                
//                 const style = window.getComputedStyle(detail);
//                 const wContainer = style.getPropertyValue("--wContainer");

//                 let timeOutSecond:any;
//                 timeOutFirst = setTimeout(function(){
//                     clearTimeout(timeOutSecond);

//                     let timeSecond = 200;

//                     if(window.innerWidth > 1023){
//                         detail.style.transition = ".3s all linear";
//                         detail.style.left = `calc(((100vw - ${wContainer}) / 2) + (${wContainer} / 100 * 64) )`;
//                         detail.style.top = `20vh`;
//                         detail.style.width = `calc(${wContainer} / 100 * 36)`;
//                     }
//                     else{
//                         timeSecond = 0;
//                     }


//                     timeOutSecond = setTimeout(function(){
//                         detail.style.top = "";
//                         detail.style.left = "";
//                         detail.style.width = "";
//                         detail.style.height = "";
//                         detail.style.transition = "";
                        
//                         detail.style.position = ".16s all linear";
//                         detail.classList.remove('visible');

//                         handlePopuped(item);

//                         // item.classList.add('showed');
//                         // document.body.classList.add('hideScroll');

//                         // const backToAll = detail.querySelector('.backtoall');
//                         // const close = detail.querySelector('.close');
        
//                         // function handleClose(){
//                         //     backToAll.removeEventListener('click', handleClose);
//                         //     close.removeEventListener('click', handleClose);
        
//                         //     item.classList.remove('showed');
//                         //     document.body.classList.remove('hideScroll');
//                         // }
//                         // backToAll.addEventListener('click',handleClose);
//                         // close.addEventListener('click',handleClose);

//                         document.body.style.pointerEvents = "";
//                     }, timeSecond)
//                 }, 0)
//             }
//         })
//     })
// }