declare const $: any;

export const _imgClass = function(){
    // Lấy thẻ img
    var imgs = document.querySelectorAll('img.svg');
    imgs.forEach(function(img:any){
        if (img.src.endsWith('.svg')){
            // Lấy nội dung SVG từ đường dẫn của thẻ img
            var xhr = new XMLHttpRequest();
            xhr.open('GET', img.getAttribute('src'), true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    // Gán nội dung SVG vào thẻ svg mới
                    var tempSvg = document.createElement('div');
                    tempSvg.innerHTML = this.responseText;
                    
        
                    // Thay thế thẻ img bằng thẻ svg mới
                    img.replaceWith(tempSvg.querySelector('svg'));
                }
            };
            xhr.send();
        }
    })
}
