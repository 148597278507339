declare const window: any;
declare const Swiper: any;
declare const $: any;

const viewHeight = window.innerHeight;

export const index = function () {
    const eBanner = <HTMLElement> document.querySelector('.banner-main');
    if (eBanner){
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
      document.body.classList.add('hideScroll');
    }
    else{
      return false;
    }
  
    const eSwBanner = document.querySelector('.banner .swiper');
    var sw = new Swiper(eSwBanner, {
        effect: 'fade',
        speed: 800,

        autoplay: {
          delay: 2400,
          pauseOnMouseEnter: false,
          disableOnInteraction: false,
        },
        
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
  
        mousewheel: {
          thresholdTime: 600,
        },
  
        a11y: {
          slideLabelMessage: '{{index}} / {{slidesLength}}'
        },
  
        on: {
          slideChange: function () {
            let len = this.slides.length - 1;
            let idxActive = this.activeIndex;

            clearTimeout(timeoutSlide);
            if (idxActive === len){
              console.log('1');
              timeoutSlide = setTimeout(function(){
                eBanner.style.transform = "translateY(-100%)";
                document.body.classList.remove('hideScroll');

                setTimeout(function(){
                  eBanner.remove();
                  const hrefRedirect = eSwBanner.getAttribute('data-redirect');
                  if (hrefRedirect){
                    window.location.href = hrefRedirect;
                  }
                }, 500)
            
              }, 3000)
            }
          },

        },
    });
  
    sw.autoplay.stop();

    var timeoutSlide:any;
    const redirectClose = function(){
      let len = sw.slides.length;
      clearTimeout(timeoutSlide);
      if (len <= 1){
        timeoutSlide = setTimeout(function(){
          eBanner.style.transform = "translateY(-100%)";
          document.body.classList.remove('hideScroll');

          setTimeout(function(){
            eBanner.remove();
            const hrefRedirect = eSwBanner.getAttribute('data-redirect');
            if (hrefRedirect){
              window.location.href = hrefRedirect;
            }
          }, 500)
        }, 10000)
      }
    }

    return {
      swiper: sw,
      redirectClose: redirectClose
    }
    // return redirectClose;
};
