declare const gsap: any;
declare const ScrollTrigger: any;
declare const $:any;
declare const window: any;

export const gsapScroll = function () {
    gsap.registerPlugin(ScrollTrigger);
    if (document.getElementById('worksPage')){
        // var boxesProject = document.querySelectorAll('.works-section .project-box');
        // boxesProject.forEach(function(item){
        //     let cateItem = <HTMLElement>item.querySelector('.project-box__cate');
        //     cateItem && window.effectBehavior(cateItem);
            
        //     let name = <HTMLElement>item.querySelector('.project-box__name');
        //     name && window.effectBehavior(name);
    
        //     let desc = <HTMLElement>item.querySelector('.project-box__desc');
        //     desc && window.effectBehavior(desc);
    
        //     let thumb = <HTMLElement>item.querySelector('.project-box-thumb');
        //     thumb && window.effectBehavior(thumb);
        // })
        // let more = <HTMLElement>document.querySelector('.works-section .loadmore');
        // more && window.effectBehavior(more);
        var boxesProject = document.querySelectorAll('.works-section .project-box');
        boxesProject.forEach(function(itemBox:HTMLElement){
            if(itemBox.getBoundingClientRect().y <= window.innerHeight - 10){
                itemBox.style.opacity = "1";
                itemBox.style.transform = "translateY(0)";
            }
        })
        setInterval(function(){
            var boxesProject = document.querySelectorAll('.works-section .project-box');
            boxesProject.forEach(function(itemBox:HTMLElement){
                if(itemBox.getBoundingClientRect().y <= window.innerHeight - 10){
                    itemBox.style.opacity = "1";
                    itemBox.style.transform = "translateY(0)";
                }
            })
        }, 300)


        // detail
        let sliderDetail= <HTMLElement>document.querySelector('.works-section .works-section--slider');
        sliderDetail && window.effectBehavior(sliderDetail);
        
        let titleDetail = <HTMLElement>document.querySelector('.works-section--detail h1');
        titleDetail && window.effectBehavior(titleDetail);

        let trInfors = document.querySelectorAll('.works-section--detail .infor-ls tr');
        trInfors.forEach(function(trItem){
            trItem && window.effectBehavior(trItem);
        })
    }

    if (document.querySelector('.related')){
        // related
        let titleRelated = document.querySelector('.related h2');
        titleRelated && window.effectBehavior(titleRelated);

        let moreRelated = document.querySelector('.related .related-more');
        moreRelated && window.effectBehavior(moreRelated);

        let relatedBoxes = document.querySelectorAll('.related .related-box');
        relatedBoxes.forEach(function(boxItem){
            boxItem && window.effectBehavior(boxItem);
        })
    }

    if (document.getElementById('newsPage')){
        let boxesNews = document.querySelectorAll('.news-section-wrapper .box-news');
        boxesNews.forEach(function(boxItem){
            let thumb = boxItem.querySelector('.thumbnail');
            thumb && window.effectBehavior(thumb, 'left');

            let time = boxItem.querySelector('.content time');
            time && window.effectBehavior(time);

            let title = boxItem.querySelector('.content h2');
            title && window.effectBehavior(title);

            let desc = boxItem.querySelector('.content .content-desc');
            desc && window.effectBehavior(desc);
        })

        let more = document.querySelector('.news-section-wrapper .loadmore');
        more && window.effectBehavior(more);

        let boxesMedia = document.querySelectorAll('.news-section-media .media-item');
        boxesMedia.forEach(function(boxItem, idx){
            if (idx % 2 === 0){
                window.effectBehavior(boxItem, 'left');              
            }else{
                window.effectBehavior(boxItem, 'right'); 
            }
        })

        let moreMedia = document.querySelector('.news-section-media .loadmore');
        moreMedia && window.effectBehavior(moreMedia);
    }
}

// $(document).ready(function (e) {
//     $('#changeType').trigger('click');
// })
// function GetProducts() {
//     $.ajax({
//         type: 'GET',
//         url: `/get-product?type=${$('#changeType').attr('data-type')}`,
//         dataType: 'html',
//         success: function (res) {
//             $('#listProducts').html(res);
//         }
//     })
// }

// function LoadMoreDataProduct() {
//     $.ajax({
//         type: 'GET',
//         url: `/get-load-more-product?type=${$('#changeType').attr('data-type')}`,
//         dataType: 'html',
//         success: function (res) {
//             $('#listProducts').html(res);
//         }
//     })
// }