
export const formHandle = function(){
    handleInput();
}

function addSpanRequired(item:any){
    let labelText = item.querySelector('label');

    if (labelText){
        let spanRequired = document.createElement('span');
        spanRequired.innerText = "*";

        labelText.appendChild(spanRequired)
    }
}

function handleInput(){
    let groupInp = document.querySelectorAll('.group-input');
    
    groupInp.forEach(function(gr:any){
        const input = gr.querySelector('input');

        if (input && input.getAttribute('type') !== 'checkbox' && input.getAttribute('type') !== 'radio'){
            let rstext = <HTMLElement> gr.querySelector('.rstext');

            if (!rstext){
                rstext = document.createElement('span');
                rstext.classList.add('rstext');
                input.parentNode.insertBefore(rstext, input.nextSibling);
            }
    
            function handleClickRstext(e:any){
                e.preventDefault;
                input.value = "";
            }
            rstext.addEventListener('click', handleClickRstext);
        }
        
        
        if (input && input.required){
            addSpanRequired(gr);
        }
        else{
            let textarea = gr.querySelector('textarea');
            if (textarea && textarea.required){
                addSpanRequired(gr);
            }
        }
    })
}