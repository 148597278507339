declare const Fancybox:any;
declare const PANOLENS:any;

const body = document.body;

function init(){  
  let ls = document.querySelectorAll('a[data-fancybox]');
  let arrayFancy:any[] = [];
  ls.forEach(function(item){
    if (!arrayFancy.includes(item.getAttribute('data-fancybox'))){
      arrayFancy.push(item.getAttribute('data-fancybox'));
    }
  })

  arrayFancy.forEach(function(item){
    Fancybox.bind(`[data-fancybox="${item}"]`, {
        Thumbs: {
          Carousel: {
            fill: false,
            center: true,
          },
        },
  
        Image: {
          zoom: true,
        },

        youtube: {
          controls: 1,
          showinfo: 0
        },

        loop: true, // Lặp lại ảnh 360 độ
        protect: true // Chống sao chép
    });
  })
}

export const popupLib = function(){
    init();
    popup360deg();
}



function popup360deg(){
  let ls = document.querySelectorAll('a[data-popup360]');
  ls.forEach(function(item){
    if (item){
      let timeoutClick:any;
      item.addEventListener('click', function(e){
        e.preventDefault();
        clearTimeout(timeoutClick);

        // remove popup
        let idPopup = body.querySelector('#popup360');
        if (idPopup){
          idPopup.remove();
          body.classList.remove('hideScroll');
        }

        // init popup360
        timeoutClick = setTimeout(function(){
          //init element
          const container = document.createElement('div');
          container.id = "popup360";
          const wrapper = document.createElement('div');
          wrapper.classList.add('wrapper');
          const closeContainer = document.createElement('button');
          closeContainer.classList.add('wrapper--close');
          const dtPopup = document.createElement('div');
          dtPopup.id= 'popup-data';

          function handleClose(){
            closeContainer.removeEventListener('click', handleClose);
            container.removeEventListener('click', containerHandle);
            document.removeEventListener('keyup', escHandle);

            body.classList.remove('hideScroll');
            container.remove();
          }
          closeContainer.addEventListener('click', handleClose);

          wrapper.appendChild(closeContainer);
          wrapper.appendChild(dtPopup);
          container.appendChild(wrapper);

          body.appendChild(container);


          let href = item.getAttribute('href');
          const panorama = new PANOLENS.ImagePanorama(href);

          const viewer = new PANOLENS.Viewer({
            container: dtPopup
          });

          viewer.add(panorama);

          const containerHandle = function(e:any){
            let target = <HTMLElement> e.target;
            if (target.getAttribute('id') === "popup360"){

              // close
              handleClose();
            }
          }

          container.addEventListener('click', containerHandle);

          function escHandle(event:any){
            if (event.key === "Escape") {
              
              // close
              handleClose();
            }
          }

          document.addEventListener('keyup', escHandle);

          body.classList.add('hideScroll');
        }, 100)
      })
    }
  })

  // const panorama = new PANOLENS.ImagePanorama('https://i.imgur.com/wTCPouq.jpg');
  
  // // var infospot, infospot2;
  // // infospot = new PANOLENS.Infospot();
  // // infospot.position.set( 1000, 100, -2000 );
  // // infospot.addHoverText( '千葉県' );

  // // infospot2 = new PANOLENS.Infospot();
  // // infospot2.position.set( 1000, 100, 2000 );
  // // infospot2.addHoverText( '神奈川県' );

  // // panorama.add(infospot , infospot2);

  // const viewer = new PANOLENS.Viewer({
  //   container: document.getElementById('popup-custom')
  // });
  // viewer.add(panorama);
}